body {
  font-family: "Roboto", sans-serif;

  overscroll-behavior-y: contain;
}

h4 {
  font-weight: 900;
}

.App {
  text-align: center;
  background: #20c7ff;

  min-height: 100vh;
}

.App.stage-pendingMultiplayerHost,
.App.stage-pendingMultiplayer {
  background: #542feb;
}

.btn.btn-secondary {
  background: #110566;
  font-weight: 700;
}

.btn {
  font-weight: 700;
  font-size: 2em;
  border: 2px solid #000;

  border-radius: 20px;
  box-shadow: 4px 5px 1px rgba(0, 0, 0, 0.2);

  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  paint-order: stroke;
  padding: 0;
}

.btn.btn-success {
  background: #30c145;
  font-weight: 700;
}

.btn.btn-primary {
  background: #4800f1;
  font-weight: 700;
}

.btn.shine {
  background-image: url("assets/shine.svg");
  background-repeat: no-repeat;
  background-position: -400px -50px;
  background-size: 300px 300px;
  transition: 0s;

  box-shadow: 6px 6px 0px black;
}

.btn.shine:hover {
  background-position: 400px -50px;
  transition: 2s;

  transform: translateX(2px) translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.screen .menu {
  width: 95%;
  max-width: 400px;
  height: 100%;
  padding-top: 30px;
}

.geo {
  width: 75px;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  margin: auto;
}

.geo-spacer {
  display: block;
  content: " ";
  height: 60px;
  width: 10px;
}

.geo1 {
  position: relative;
  width: 80px;
  top: -40px;
  left: 50%;
  margin-left: -40px;
  margin-bottom: -30px;
}

.credit-wrapper {
}

.btn-close {
  position: absolute;
  width: 40px;
  right: 20px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  top: -5px;
  background: #542feb url("assets/btn-close.png") no-repeat center center;
  background-size: 18px;

  color: #fff;
  line-height: 36px;
  font-size: 20px;
  cursor: pointer;
}

.players-bar .avatar-me .avatar {
  outline: 3px solid #ff005b;
}
